<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="items"
              :sort-mapping="sortMapping"
              :title="'documents to review'"
              :total="itemsTotal"
              :fetch-path="'traces/fetch'"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <b-dropdown-item @click="$router.push({name: 'cae.view-document', params: {id: field.doc_id}, query: {host: field.host.id}})">
                      <i
                        class="me-50"
                        data-feather="eye"
                      /> View document
                    </b-dropdown-item>
                    <b-dropdown-item
                      target="_blank"
                      :href="field.doc_url"
                    >
                      <i
                        class="me-50"
                        data-feather="download"
                      /> <span>Download document</span>
                    </b-dropdown-item>
                    <template>
                      <hr>
                      <b-dropdown-item @click="acceptDocument(true, field.doc_id, field.host)">
                        <i
                          class="me-50"
                          style="color: limegreen;"
                          data-feather="check"
                        /> <span style="color: limegreen;">Accept document</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="acceptDocument(false, field.doc_id, field.host)">
                        <i
                          class="me-50"
                          data-feather="x"
                          style="color: red;"
                        /> <span style="color: red;">Reject document</span>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </td>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      sortedField: 'Host',
      defaultFields: [
        {
          name: 'Host',
          checked: true,
          order: 1,
        },
        {
          name: 'Department',
          checked: true,
          order: 2,
        },
        {
          name: 'Title',
          checked: true,
          order: 3,
        },
        {
          name: 'Section',
          checked: true,
          order: 4,
        },
        {
          name: 'Subsection',
          checked: true,
          order: 5,
        },
        {
          name: 'Status',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Host: 'host.name',
        Department: 'department.name',
        Title: 'title',
        Section: 'section.name',
        Subsection: 'subsection',
        Status: 'status',
      },
      title: 'Alerts',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      items: 'cae/alerts',
      itemsTotal: 'cae/alertsTotal',
    }),
  },
  async mounted() {
    await this.$store.dispatch('cae/fetchAlerts')
    await this.$store.dispatch('modals/fetchUserFields', 'cae_alerts')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'cae_alerts',
      })
    }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async acceptDocument(accept, id, host) {
      Vue.swal({
        title: `Do you want to ${accept ? 'accept' : 'reject'} this document?`,
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.isConfirmed) {
          await this.$store.dispatch('cae/acceptDocument', { file: id, accept, host })
          await this.$store.dispatch('cae/fetchAlerts')
          Vue.swal('Document status changed successfully', '', 'success')
          setTimeout(() => {
            // eslint-disable-next-line no-undef
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 100)
        }
      })
    },
  },
}
</script>
